.largeTitle{
    font-size: 25px;
    margin-top: 25px;
}

.detailViewDescription{
    max-width: 500px;
    margin-bottom: 20px;
}

.backButton svg{
    padding-bottom: 2px;
}

.backButton{
    margin-bottom: 10px;
}

.idSpan {
    font-size: 17px;
    color: grey;
}