.altTextComponent{
    background-color: grey;
    align-self: center;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 5%;
}

.altText{
    width: 100%;
}

.emptyList{
    width: 100%;
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 200px;
    font-size: 18px;
    color: grey;
}