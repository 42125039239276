@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bp3-popover {
  box-shadow: .5px .5px 4px -1px grey;
  transform: scale(1) !important;
}

.bp3-tab-list {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-bottom: lightgrey solid 3px;
}

.bp3-tab-panel{
  margin-top: 0;
}


