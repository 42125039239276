.sidebar{
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-left: 25px;
    padding-right: 25px;
}

.largeTitle{
    font-size: 30px;
    margin-bottom: 0;
    margin-top: 5px;
}



.idText{
    margin-top: -5px;
    font-size: 15px;
    font-weight: bold;
}


.sideBarSpacer{
    flex-grow: 1;
}

.backButton{
    width: 90px;
    margin-top: 10px;
    font-size: 14px;
}

.backButton svg{
    margin-bottom: 3px;
}

