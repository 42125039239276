.homeBackground{
    position: absolute;
    z-index: -5;
    height: 800px;
    overflow: hidden;
    width: 100vw;
}

.homeViewWrapper{
    min-height: 100vh;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 300px;
    flex-direction: column;
}

.homeJumbotron{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
}

.jumbotronSpace{
    flex-basis: 250px;
    flex-grow: 4;
}

.jumbotronText{
     display: flex;
     flex-direction: column;
     flex-basis: 250px;
     height: 180px;
     flex-grow: 1;
    margin-right: 20px;
 }

.jumbotronText>div {
    background: #29893C;
    color: white;
    width: fit-content;
    padding: 5px;
    margin-bottom: 3px;
}

.innovonto{
    font-size: 50px;
    margin-bottom: 10px;
    padding: 5px;
}

.jumboSubtitle{
    margin-left: 30%;
    font-size: 25px;
}

.contentWrapper{
    width: 100%;
    margin-top: 50px;
}

.headLineCards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.homeViewText{
    padding: 10%;
    padding-top: 30px;
}

.homeViewText p{
    word-break: break-word;
    font-size: 15px;
    line-height: 150%;
    margin-bottom: 35px;
}
