
.smallTitle{
    margin-top: 10px;
    font-size: 20px;
    margin-bottom: 0;
}

.ideaDetails{
    border-top: solid #777777 1px;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-right: 2px;
    padding-left: 2px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-bottom: 15px;
    overflow-y: scroll;
}

.clusterConcepts{
    border-top: solid #777777 1px;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-right: 5px;
    padding-left: 5px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    overflow-y: scroll;
}

.countCol{
    text-align: end;
    width: 20%;
}

.conceptCol{
    width: 80%;
}

.sideBarTable td{
    padding: 2px;
    padding-top: 4px;
    padding-bottom: 0;
    border-top: dashed #777777 0.5px;
}

.sideBarTable{
    width: 270px;
    margin-top: 5px;
    table-layout: fixed;
}