.eventMarker{
    transform: translate(-50%, -50%);
}

.noContent{
    color: orangered;
    padding: 5px;
}

.stringContent{
    padding:5px;
}

.imageContent{
    padding: 5px;
}

.popover{
    max-width: 300px;
}
