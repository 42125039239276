
.sessionPageWrapper{
    display: flex;
    flex-direction: row;
}

.sessionDetails{
    border-top: solid #777777 1px;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-right: 2px;
    padding-left: 2px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-bottom: 15px;
    overflow-y: scroll;
}

.smallTitle{
    margin-top: 10px;
    font-size: 20px;
    margin-bottom: 0;
}

.radioButton{
    width: 50%;
}