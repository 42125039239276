.zoomWindowWrapper{
    display: flex;
    flex-direction: row;
}

.solutionMapWrapper{
    background-color: whitesmoke;
    overflow: hidden;
}

.toolBar{
    position: absolute;
    padding: 15px;
    display: flex;
    flex-direction: row;
}

.basicCircle{
    cursor: pointer;
    stroke: whitesmoke;
}

.infoHoverPopover{
    position: absolute;
    background-color: rgba(220, 220, 220, 0.85);
    width: 300px;
    padding: 10px;
    padding-bottom: 0;
    z-index: 2;
    border-radius: 10px;
}

.zoomBox{
    z-index: 1;
}

