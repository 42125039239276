.networkPanelWrapper{
    background-color: whitesmoke;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.networkSectionWrapper{
    flex-basis: 250px;
    flex-grow: 1;
    padding: 20px;
    margin-bottom: 20px;

}
.neighbourhoodPreview{
    width: 100%;
    max-width: 330px;
    display: flex;
    border: solid #29893C 2px;
    background-color: grey;
}

.inspiredByPreview{
    width: 100%;
    max-width: 330px;
    height: 167px;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    border: solid #29893C 2px;
    overflow-y: scroll;
}

.notAvailable img {
    filter: grayscale(60%) opacity(75%);
}

.notAvailable>div{
    cursor: not-allowed;
    background-color: rgba(128, 128, 128, 0.25);
}

.basicDiamond{
    height: 20px;
    width: 20px;
    background-color: black;
    transform: rotate(45deg);
}

.inspirationItem{
    padding: 15px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
}

.inspirationText{
    margin-left: 10px;
    margin-top: 3px;
}