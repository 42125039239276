.largeTitle{
    font-size: 25px;
    margin-top: 25px;
}

.projectSearchInput{
    width: 300px;
}

.projectOptionsWrapper{
    margin-top: 20px;
}

.projectOption{
    margin-bottom: 5px;
    cursor: pointer;
    font-weight: bold;
}