
.userViewWrapper{
    width: 100%;
    background: white;
}

.diamondListItem{
    width: 100%;
    padding: 20px;
    display: flex;
}

.basicDiamond{
    height: 20px;
    width: 20px;
    background-color: black;
    transform: rotate(45deg);
    margin-right: 15px;
}

.list{
    width: 100%;
    background-color: whitesmoke;
}

.listItemSpan{
    margin-top: 3px;
}