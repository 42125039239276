
.searchComponentWrapper{
    position: absolute;
    background-color: lightgrey;
    align-self: center;
}

.searchHeaderWrapper{
    width:100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    font-size: 16px;
    background-color: whitesmoke;
    padding: 10px;
}
.searchInput{
    width: 33%;
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.searchBodyWrapper{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.pagingControls{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    background-color: whitesmoke;
}

.pagingBody{
    flex-grow:1;
    display: flex;
    flex-direction: column;
}

.pageNumber{
    margin: 7px;
}

.resultMessageWrapper{
    margin-top: 15%;
    height: 35%;
    min-height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 18px;
    text-align: center;
}

.resultListWrapper{
    padding-left: 25px;
    padding-right: 25px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 92%;
}

.listItem{
    background-color: whitesmoke;
    margin-top: 20px;
    padding-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 10px;
}

.listText{
    word-break: normal;
    font-size: 12px;
}

.resultGridWrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
}

.gridItem{
    background-color: whitesmoke;
}

.gridItemHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.gridItemId{
    font-size: 10px;
    word-break: break-all;
}

.gridItemBody{
    font-size: 12px;
    overflow-y: scroll;
}

.gridItemResultN{
    padding-right: 5px;
    padding-left: 5px;
}

.resultIdeaWrapper{
    display: flex;
    flex-direction: column;
    padding: 30px;
    overflow: scroll;
}

.resultIdeaCounter{
    height:50px;
    width: 100%;
    display: flex;
}

.resultIdeaCounter div{
    margin: auto;
}

.resultIdeaRating h3{
    margin-bottom: 10px;
}




