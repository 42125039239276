
.modelViewWrapper{
    width: 100%;
    background: white;
}

.modelIFrame{
    width: 100%;
    margin-top: -30px;
    border: none;
}

