.idSpan{
    color: grey;
    font-size: 17px;
}

.metaDataWrapper{
    margin-top: 10px;
}

.metaDataWrapper p{
    margin-bottom: 3px;
}

h3 {
    margin-bottom: 5px;
    margin-top: 8px;
}

.reviewWrapper {
    margin-bottom: 20px;
}

.reviewWrapper p {
    margin-bottom: 3px;
}

.categoryLabels{
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: default !important;
}

.horizontalDivider{
    border-top: solid #999999 1px;
    margin-top: 8px;
}